.continue-btn {
    background: #3170f5;
    border: none;
    border-radius: 3px;
    font-weight: bold;
    color: #fff;
    font-size: 18px;
    letter-spacing: 2px;
    height: 50px;
    width: 200px;
    outline: none;
}

.calendar-btn-container {
    display: flex;
    justify-content: center;
}

.input {
    padding: 10px;
    width: 400px;
}

.input-wrapper {
    display: flex;
    flex-direction: column;
}

.btn {
    background: #434242;
    border: 1px solid black;
}

.timeline {
    display: flex;
    flex-direction: column;
}

.btn-end {
    color: #fff;
    border-radius: 3px;
    font-size: 20px;
    cursor: pointer;
    width: 100%;
    height: 50px;
    border: 0;
}

button.minus.disabled,
button.plus.disabled {
    cursor: default;
    opacity: 0.3;
    background-color: #9e9e9e;
}

.booking-persons,
.booking-date,
.booking-time {
    font-weight: 600;
    font-size: 24px;
    margin: 10px 0;
}

.booking-time {
    margin-bottom: 50px;
}

.logoForSpecialMalls {
    max-width: 400px;
    width: 100%;
    margin: 10px 0;
    
    @media (max-width: 767.98px) {
        max-width: 200px;
    }
    
    @media (max-width: 575.98px) {
        max-width: 400px;
    }
}
