.steps-wrapper {
    display: flex;
    justify-content: center;
}

.steps {
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 10px 0;
    
    @media only screen and (max-width: 575.98px){
        width: 100%;
      }
}