// main user colors

$darkBlue: #002857;
$blue: #3170f5;
$slate: #79879d;
$gray: #999;
$whiteBorder: #eee;
$white: #fff;
$black: #000;
$red: #db4437;
$yellow: #deb400;
$mov: #000;
$fov: #000;
$pov: #000;
$paov: #000;
$vov: #000;