.special-headline-for-mall {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search-input {

    @media (max-width: 400px) {
        font-size: 14px;
    }
    
    @media (max-width: 350px) {
        font-size: 12px;
    }
}