.steps__step {
    width: 20%;
    margin: 5px;
    display: flex;
    flex-direction: column;
}

.steps__step-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.steps__number {
    position: relative;
    border-radius: 3px;
    text-align: center;
    padding: 10px 20px;
    width: 50px;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    border: 1px solid #434242;

    @media only screen and (max-width: 575.98px) {
        padding: 4px 9px;
        width: 35px;
    }
}

.steps__number--active {
    color: white;
    border: none;
}

.steps__title {
    text-align: center;

    @media only screen and (max-width: 575.98px) {
        font-size: 14px;
    }
}