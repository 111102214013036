@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';
@import "colors.scss";

#logo {
  width: 181px;
  height: 40px;
  background-image: url("assets/images/logo-mps@2x.png");
  background-repeat: no-repeat;
  background-size: 181px 24px;
  margin: 0 50px 0 0;
}
#logo span {
  display: block;
  text-indent: -9999px;
}

.logo-signature {
  color: white;
  text-align: center;
}

@media (max-width: 1200px) {
    .company-text {
      flex-direction: column;
      align-items: flex-start;
    }
}

.App .navbar {
  padding: 8px 0 6px;

  .container {
    position: relative;
  }

  .navbar-nav {
    font-size: 15px;

    .nav-item {
      padding: 0 0 3px;
      margin: 0 0 0 30px;
      cursor: pointer;
      box-sizing: border-box;

      &:hover,
      &[data-active="true"] {
        .nav-link {
          color: $white;
          padding: 0 0 2px;
          border-bottom: 1px solid $white;
        }
      }
    }
    .nav-link {
      color: $white;
      padding: 0 0 3px;
    }
  }

  .navbar-toggler {
    background: rgba(255,255,255,0.5) !important;
    top: -8px;
    left: 0;
    height: 54px;
    padding: 0 5px;
    position: absolute;
    border-radius: 0;
    outline: none;

    &:hover {
      background: rgba(255,255,255,0.7) !important;
    }

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }

  .showed + .language-selector {
    display: none;
  }
  .showed.navbar-collapse {
    margin: 10px 0 0;

    .navbar-nav .nav-item {
      margin: 0;
      padding: 10px 0;
      font-size: 20px;
      font-family: 'sf_pro_displaylight', Arial, Helvetica, sans-serif;

      .nav-link {
        display: inline-block;
      }
    }
  }
}

#Homepage {
  background-image: linear-gradient(to right, $darkBlue 24%, rgba(1, 38, 85, 0.6) 66%, rgba(0, 40, 87, 0.3) 100%),
    url("assets/images/background.jpg");

  .container {
    height: 100%;
  }

  .frame {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    color: $white;
  }

  header {
    h2 {
      font: 54px/58px 'sf_pro_displaylight', Arial, Helvetica, sans-serif;
      margin: 0 0 35px;

      strong {
        display: block;
        font-family: 'sf_pro_displaybold', Arial, Helvetica, sans-serif;
      }
    }

    p {opacity: 0.5;}

    .home-buttons {
      margin-top: 60px;

      button {
        display: inline-block;
        width: auto;
        margin: 0 16px 0 0;
        line-height: 54px;
        font-size: 18px;
        padding: 0 35px;
        border: 0;
        color: $white;
        background: none;
        border-radius: 4px;
        box-sizing: border-box;
        cursor: pointer;
        transition: all 500ms ease-in-out;

        &.fill {
          background-color: $blue;
          opacity: 0.8;
          &:hover {
            opacity: 1;
          }
        }
        &.frame {
          border: solid 2px rgba(255, 255, 255, 0.3);
          line-height: 50px;
          &:hover {
            border-color: rgba(255, 255, 255, 0.8);
          }
        }
      }
    }
  }
}

#HowTo {
  background-color: #f5f5f6;
  color: $black;

  .container {
    height: 100%;
  }

  .frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: flex-start;
  }

  header {
    width: 100%;
    margin: 0 0 50px;

    h2 {
      font: 44px/48px 'sf_pro_displaylight', Arial, Helvetica, sans-serif;
      color: rgba(0, 0, 0, 0.3);

      strong {
        font-family: 'sf_pro_displaybold', Arial, Helvetica, sans-serif;
        color: rgba(0, 0, 0, 1);
      }
    }
  }

  .row {
    width: 100%;
  }

  .holder {
    display: flex;
  }

  .circle {
    display: block;
    width: 60px;
    height: 60px;
    font-family: 'sf_pro_displayregular', Arial, Helvetica, sans-serif;
    font-size: 28px;
    line-height: 60px;
    text-align: center;
    background-color: rgba(0, 40, 87, 0.1);
    color: $black;
    border-radius: 100%;
    margin: 0 25px 0 0;
  }

  .text-box {
    font-size: 16px;
    width: 60%;

    h3 {
      font-family: 'sf_pro_displaybold', Arial, Helvetica, sans-serif;
      font-size: 24px;
    }
  }
}

#Booking {
  background: $white;
  color: $black;

  .container {
    height: 100%;
  }

  .frame {
    padding-top: 60px;
  }

  .paragraphInStore {
    font: 22px 'sf_pro_displayregular', Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  h2 {
    font: 44px/48px 'sf_pro_displaylight', Arial, Helvetica, sans-serif;
    color: rgba(0, 0, 0, 0.3);
    margin: 0 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .company-text {
      display: flex;
      margin-left: 20px;
    }

    strong {
      font-family: 'sf_pro_displaybold', Arial, Helvetica, sans-serif;
      color: rgba(0, 0, 0, 1);
      margin-right: 10px;
    }
  }

  .search-bar {
    width: 100%;
    margin: 0 0 15px;

    .field {
      background-color: #f5f5f6;
      border-radius: 5px;
      padding: 0 10px;
      display: flex;
      margin: 0 0 15px;
    }

    button {
      padding: 10px;
      background: none;
      cursor: pointer;
      border: 0;
      opacity: 0.5;
      color: $black;
    }

    input {
      padding: 10px;
      background: none;
      border: 0;
      width: 100%;
      box-sizing: border-box;
    }

    p {
      span {
        color: $blue;
        text-decoration: underline;
      }
    }
  };

  .results-list {
    height: 48vh;
    width: 100%;
    overflow: auto;
    
    &.specialMalls {
      height: 38vh;
    }

    li {
      border-radius: 3px;
      display: inline-block;
      vertical-align: top;
      width: 25%;
      box-sizing: border-box;
      padding: 10px 16px;

      strong {
        font-family: 'sf_pro_displaybold', Arial, Helvetica, sans-serif;
        display: block;
        margin: 0 0 6px;
      }

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        background-color: rgba(49, 112, 245, 0.1);
        cursor: pointer;
      }
    }
  }

  .gradient-holder {
    position: relative;
    margin: 20px 0;
  }

  .gradient {
    width: calc(100% - 12px);
    left: 0;
    bottom: 0;
    height: 5px;
    background: linear-gradient(180deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0.7) 100%);
    position: absolute;
  }
}

#Contacts {
  background: #f5f5f6;
  color: $black;
  position: relative;

  .bg {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 40%;
    background: url(assets/images/group-3.jpg) 100% 0 no-repeat;
    background-size: auto 100%;
  }

  .container {
    height: 100%;
  }

  .frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    position: relative;
  }

  header {
    width: 100%;
    font-size: 36px;
    color: $black;

    h2 {
      font: 44px/48px 'sf_pro_displaybold', Arial, Helvetica, sans-serif;
      margin: 0 0 100px;
    }
    p {
      margin: 0 0 25px;
    }
    a {
      color: $black;

      &:hover {
        text-decoration: none;
      }
    }
  }

  footer {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 60%;

    .copyright {
      float: left;
    }

    ul {
      float: right;

      li {
        float: left;
        margin: 0 0 0 15px;

        a {
          color: $black;
        }
      }
    }
  }
}

.go-back {
  position: relative;
  display: flex;
  align-items: center;
  color: $blue;
  font-size: 18px;
  border: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
}

.arrow-circle-left {
  margin-right: 25px;
}

.static-page {
  padding: 30px 0;
  line-height: 28px;

  header {
    margin: 0 0 45px;

    h2 {
      margin: 18px 0 0;
      font-family: 'sf_pro_displaybold', Arial, Helvetica, sans-serif;
      font-size: 48px;
      line-height: 54px;
    }
  }

  article {
    p {margin: 0 0 15px;}
  }
}

.booking-page {
  padding: 30px 0;
  line-height: 28px;

  .border-row {
    border-bottom: 1px solid #ccc;
    margin: 0 0 30px;
  }

  h2 {
    font-family: 'sf_pro_displaybold', Arial, Helvetica, sans-serif;
    color: rgba(0, 0, 0, 1);
    font-size: 24px;
    margin: 0 0 30px;
  }

  header {
    margin: 0 0 45px;
    font-size: 23px;

    .header-row {
      margin-top: 10px;
    }

    h1 {
      margin: 0 0 6px;
      font: 44px/48px 'sf_pro_displayregular', Arial, Helvetica, sans-serif;
      color: rgba(0, 0, 0, 0.3);

      strong {
        font-family: 'sf_pro_displaybold', Arial, Helvetica, sans-serif;
        color: rgba(0, 0, 0, 1);
        text-transform: capitalize;
      }
    }

    .button {
      background: $blue;
      color: $white;
      border-radius: 3px;
      line-height: 54px;
      font-size: 20px;
      padding: 0 36px;
      cursor: pointer;
      border: 0;
    }
  }

  .number-select {
    height: 54px;
    display: flex;
    margin-bottom: 40px;

    input {
      background-color: #f5f5f6;
      padding: 0;
      width: 240px;
      border: 0;
      text-align: center;
      font-size: 18px;
    }

    button {
      display: inline-block;
      vertical-align: top;
      border: 0;
      cursor: pointer;
      width: 54px;
      background-color: #e6e6e9;
      background-position: 50%;
      background-repeat: no-repeat;
      font-size: 30px;
      margin: 0;
      padding: 0;
      text-indent: -9999px;

      &.minus {
        background-image: url("assets/images/button-minus.png");
        background-size: 26px 2px;
      }
      &.plus {
        background-image: url("assets/images/button-plus.png");
        background-size: 24px;
      }
    }
  }

  .periods {
    margin-bottom: 20px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
  }

  .available-slots {
    margin-bottom: 20px;

    .slot {
      width: 100%;
      text-align: center;
      border: 0;
      background: #f5f5f6;
      line-height: 40px;
      margin: 0 0 8px;

      &:hover,
      &[data-active="true"] {
        background: $blue;
        color: $white;
      }

      &.fov:hover,
      &.fov[data-active="true"] {
        background: $fov;
        color: $white;
      }

      &.mov:hover,
      &.mov[data-active="true"] {
        background: $mov;
        color: $white;
      }

      &.pov:hover,
      &.pov[data-active="true"] {
        background: $pov;
        color: $white;
      }

      &.paov:hover,
      &.paov[data-active="true"] {
        background: $paov;
        color: $white;
      }

      &.vov:hover,
      &.vov[data-active="true"] {
        background: $vov;
        color: $white;
      }
    }
  }

  .error-text {
    margin: 0 0 30px;
    color: $red;
  }

  .booking-form {
    label {
      font-size: 24px;
      font-family: 'sf_pro_displaybold', Arial, Helvetica, sans-serif;
      color: rgba(0, 0, 0, 1);
      margin: 0 0 30px;
    }

    .input-row {
      margin: 0 0 30px;
    }

    .booking-email-input {
      height: 54px;
      background: #f5f5f6;
      border-radius: 3px;
      padding: 0 20px;
      font-size: 16px;
      border: 0;
      margin: 0 12px 0 0;
      width: 376px;
      box-sizing: border-box;
      vertical-align: top;
      display: inline-block;
    }

    .booking-agreement {
      margin-bottom: 25px;
      font-family: 'sf_pro_displaybold', Arial, Helvetica, sans-serif;
      font-size: 14px;
    }

    .booking-agreement-checkbox {
      margin-left: 10px;
    }

    button {
      background: $blue;
      color: $white;
      border-radius: 3px;
      font-size: 16px;
      line-height: 54px;
      font-size: 20px;
      padding: 0 36px;
      cursor: pointer;
      border: 0;
      vertical-align: top;
      display: inline-block;
      outline: none;

      &.sent {
        background: $yellow;
      }

      .ico-ok {
        background: url(assets/images/ico-ok.png) no-repeat 0 50%;
        background-size: 20px;
        padding: 0 0 0 30px;
      }
    }
  }

  .resend {
    color: $gray;
    font-size: 16px;
    margin: -30px 0 40px;

    span {
      color: $blue;
      cursor: pointer;
    }
  }

  .message {
    font-size: 24px;
    line-height: 1.5;
    font-family: 'sf_pro_displayregular', Arial, Helvetica, sans-serif;
    color: rgba(0, 0, 0, 1);

    strong {
      font-family: 'sf_pro_displaybold', Arial, Helvetica, sans-serif;
    }

    p {margin: 0;}
  }
}

@media (max-width: 991.98px) {
  #logo {
    margin-left: 60px;
  }
  #Booking {

    .results-list {
      height: 42vh;
      margin-bottom: 20px;

      &.specialMalls {
        height: 32vh;
      }
    }

    h2 {
      font-size: 34px;
      line-height: 38px;
    }

    .paragraphInStore {
      font-size: 20px;
    }
  }
  #Contacts {
    .container {
      padding: 0;
    }
    footer {
      .copyright {
        float: right;
        margin: 0 0 20px;
      }
    }
  }

  .booking-page header .button {
    font-size: 16px;
    padding: 0 15px;
  }

  main .react-datepicker {
    font-size: 14px;
  }
  main .react-datepicker__day-name,
  main .react-datepicker__day,
  main .react-datepicker__time-name {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (max-width: 767.98px) {
  #Booking {
    .paragraphInStore {
      font-size: 16px;
    }

    h2 {
      font-size: 30px;
      line-height: 34px;
      margin-bottom: 17px;
      flex-direction: column;

      img {
        display: block;
        margin: 0 auto 10px;
      }

      .company-text{
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: 0;
      }
    }
  }
  #Contacts {
    .bg {
      width: 30%;
    }

    header{
      font-size: 28px;

      h2 {
        margin: 0 0 40px;
      }
    }
    footer {
      .copyright {
        float: none;
      }

      ul {
        float: none;
        text-align: left;

        li {
          float: none;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .language-selector {
    margin-right: 10px;
  }
  #logo {
    margin-right: 0;
  }
  .App .navbar {
    .navbar-toggler {left: 0}
    .showed.navbar-collapse .navbar-nav .nav-item {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  #Homepage {
    header {
      h2 {
        font-size: 30px;
        line-height: 34px;
      }
      .home-buttons button {
        width: 100%;
        margin: 0 0 10px;
      }
      p {width: 90%;}
    }
  }

  #HowTo {
    header {
      margin: 0 0 20px;
      h2 {
        font-size: 22px;
        line-height: 32px;
      }
    }
    .circle {
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
    }
    .text-box {
      font-size: 14px;

      h3 {
        font-size: 18px;
        line-height: 21px;
      }
    }
  }

  #Booking {
    h2 {
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 20px;
    }

    .gradient-holder {
      width: 90%;
    }

    .results-list {
      height: 44vh;
    }

    .results-list li {
      width: 100%;
      //padding: 6px 10px;
    }
  }

  #Contacts {
    .container {
      padding: 0 15px;
    }
    header {
      margin: 0 0 20px;
      font-size: 24px;

      h2 {
        font-size: 30px;
        margin: 0 0 20px;
      }
      p {
        margin: 0 0 10px;
        width: 90%;
      }
    }
    .bg {
      display: none;
    }

    footer {
      width: 100%;
    }
  }

  .go-back {
    font-size: 15px;
  }

  .booking-page {
    .booking-form .booking-email-input {
      width: 280px;
      margin: 0 0 10px;
    }
    header {
      font-size: 16px;
      margin: 0 0 25px;

      h1 {
        font-size: 28px;
        line-height: 32px;
      }
      p {margin: 0 0 10px;}
    }

    h2 {
      font-size: 19px;
    }
    .number-select input {width: 170px;}
    .available-slots {
      padding: 0 0 40px;
    }
    .react-datepicker {
      margin: 0 0 30px;
    }
    .reservation-frame {
      position: fixed;
      background: $white;
      border: 1px solid #ccc;
      padding: 10px;
      bottom: 0;
      left: 0;
      z-index: 5;

      button {
        width: 100%;
      }
    }
  }

  .static-page header {
    margin: 0 0 20px;

    h2 {
      font-size: 34px;
      line-height: 38px;
    }
  }
}

@media (max-width: 400px) {
  #Booking {
    .results-list {
      height: 35vh;
    }
  }
}
.booking-black{
  font-weight: bold;
  background: white!important;
  color: black!important;
  border: 2px solid black!important;
  border-radius: 3px!important;
  font-size: 16px!important;
  line-height: 54px!important;
  padding: 0 36px!important;
  cursor: pointer!important;
  vertical-align: top!important;
  display: inline-block!important;
  outline: none!important;
}

.booking-danger{

  background: white!important;
  color: red!important;
  border: 1px solid black!important;
  border-radius: 0!important;
  font-size: 16px!important;
  line-height: 54px!important;
  padding: 0 36px!important;
  cursor: pointer!important;
  vertical-align: top!important;
  display: inline-block!important;
  outline: none!important;
}
#email-button-res{
  background: #3170f5;
  border: none;
  border-radius: 3px;
  font-weight: 700;
  color: #fff;
  font-size: 18px;
  letter-spacing: 2px;
  width: 100%!important;
  height: 100%;
  outline: none;
  text-decoration: none;
  display: block;
}
a:not([href]) {
  color: white!important;
  text-decoration: none;
}
#buttonContainer{
  background: none;
  margin-right: 20px;
  padding: 0;
  width: 100px ;
}

.timeline-row {
  margin-bottom: 20px;

  @media only screen and (max-width: 575.98px) {
    margin-bottom: 0;
  }
}

.info-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  
  @media (max-width: 575.98px) {
      flex-direction: column;
  }
}
