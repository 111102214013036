@import "reset.css";
@import "colors.scss";

// fonts
@font-face {
  font-family: 'sf_pro_displayregular';
  src: url('./assets/fonts/sfprodisplay-regular.woff2') format('woff2'),
    url('./assets/fonts/sfprodisplay-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sf_pro_displaymedium';
  src: url('./assets/fonts/sfprodisplay-medium.woff2') format('woff2'),
    url('./assets/fonts/sfprodisplay-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sf_pro_displaylight';
  src: url('./assets/fonts/sfprodisplay-light.woff2') format('woff2'),
    url('./assets/fonts/sfprodisplay-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sf_pro_displaybold';
  src: url('./assets/fonts/sfprodisplay-bold.woff2') format('woff2'),
    url('./assets/fonts/sfprodisplay-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
}

body {
  font: 16px 'sf_pro_displayregular', Arial, Helvetica, sans-serif;
}

.title {
  margin: 20px auto 0;
  text-align: center;
}

.links {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.links .link {
  margin-top: 20px;
}

.component {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.custom-pagination {
  margin: 0;
  position: fixed;
  right: 24px;
  width: 12px;
  z-index: 999;
  top: 50%;
  transform: translateY(-50%);
}

.custom-pagination > li > a {
  width: 12px;
  height: 12px;
  background-color: rgba(49, 112, 245, 0.4);
  text-indent: -9999px;
  display: inline-block;
  vertical-align: top;
  border-radius: 100%;
  transition: background 250ms ease-in-out;
}
#fp-nav.fp-right {
  right: 8px !important;
}
#fp-nav ul li a span {
  background-color: rgba(49, 112, 245, 0.4) !important;
  width: 12px !important;
  height: 12px !important;
  margin: 0 !important;
  left: 0 !important;
  top: 0 !important;
}

.custom-pagination>li:hover>a,
.custom-pagination>li.active>a,
#fp-nav ul li a.active span,
#fp-nav ul li a:hover span,
#fp-nav ul li a.active:hover span {
  width: 12px !important;
  height: 12px !important;
  left: 0 !important;
  top: 0 !important;
  background-color: $blue !important;
  margin: 0 !important;
}

.custom-pagination>li:not(:last-of-type)>a,
.custom-pagination>li:not(:last-of-type)>span {
  margin: 0 0 8px;
}

.language-selector {
  color: $white;

  li {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 10px;
    cursor: pointer;
  }
  li.active,
  li:hover {
    text-decoration: underline;
  }
}

button:disabled,
button[disabled="true"] {
  cursor: not-allowed;
  opacity: 0.5;
}

/* custom scrollbar */

/* Firefox-only */
*[data-customscroll="true"] {
  scrollbar-width: thin;
  scrollbar-color: $slate $darkBlue;
}

/* Works on Chrome/Edge/Safari */
*[data-customscroll="true"]::-webkit-scrollbar {
  width: 12px;
}

*[data-customscroll="true"]::-webkit-scrollbar-track {
  background: $slate;
}

*[data-customscroll="true"]::-webkit-scrollbar-thumb {
  background-color: $darkBlue;
  border-radius: 20px;
  border: 3px solid $slate;
}

/* date-picker */
.react-datepicker,
.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker {
  border: 0;
  font-size: 16px;
}

.react-datepicker__header {
  background: none;
  border: 0;
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  border: 1px solid $whiteBorder !important;
  margin: 0;
  width: 2.7rem;
  line-height: 2.7rem;
  overflow: hidden;
  box-sizing: border-box;
  vertical-align: top;
  background: $white;
  outline: none;
}

.react-datepicker__day-name {
  border: 0 !important;
  color: $gray;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  border-radius: 0;
}

.react-datepicker__day--selected {
  background: $blue;
  outline: none;
}

.react-datepicker__day--selected:hover {
  background: $blue !important;
  color: $white;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day:hover {
  border-radius: 0 !important;
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  outline: none;
}

.react-datepicker__day:hover {
  border-color: $blue !important;
}

.react-datepicker__current-month {
  font-size: 20px;
}

.react-datepicker__navigation {
  width: 13px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: 13px 23px;
  border: 0;
}

.react-datepicker__navigation--previous {
  background-image: url(assets/images/ico-back.png);
}
.react-datepicker__navigation--next {
  background-image: url(assets/images/ico-forward.png);
}

// fov,mov,pov,paov,vov datepicker

.react-datepicker__day--selected {

  &.fov {
    background: $fov;
    outline: none;

    &:hover {
      background: $fov !important;
      color: $white;
    }
  }
  
  &.mov {
    background: $mov;
    outline: none;

    &:hover {
      background: $mov !important;
      color: $white;
    }
  }

  &.pov {
    background: $pov;
    outline: none;

    &:hover {
      background: $pov !important;
      color: $white;
    }
  }
  
  &.paov {
    background: $paov;
    outline: none;

    &:hover {
      background: $paov !important;
      color: $white;
    }
  }

  &.vov {
    background: $vov;
    outline: none;

    &:hover {
      background: $vov !important;
      color: $white;
    }
  }
}

.react-datepicker__day {

  &.fov:hover {
    border-color: $fov !important;
  }

  &.mov:hover {
    border-color: $mov !important;
  }

  &.pov:hover {
    border-color: $pov !important;
  }
  
  &.paov:hover {
    border-color: $paov !important;
  }
  
  &.vov:hover {
    border-color: $vov !important;
  }
}

@media (max-width: 575.98px) {
  #fp-nav ul li a:hover span {
    width: 12px !important;
    height: 12px !important;
    left: 0 !important;
    top: 0 !important;
    background-color: rgba(49, 112, 245, 0.4) !important;
    margin: 0 !important;
  }
}

.grecaptcha-badge:hover {
  right: 0!important;
}
